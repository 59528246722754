export const materialsFields = [
  {
    name: 'lowCarbonFactor',
    label: 'Low Carbon Factor',
    suffix: 'kgCO₂e/kg',
    prefix: 'm',
    rules: 'required'
  },
  {
    name: 'medianCarbonFactor',
    label: 'Median Carbon Factor',
    suffix: 'kgCO₂e/kg',
    prefix: 'm',
    rules: 'required'
  },
  {
    name: 'highCarbonFactor',
    label: 'High Carbon Factor',
    suffix: 'kgCO₂e/kg',
    prefix: 'm',
    rules: 'required'
  },
  {
    name: 'density',
    label: 'Density',
    suffix: 'kg/M^3',
    prefix: 'm',
    rules: 'required'
  },
  {
    name: 'mass',
    label: 'Mass',
    suffix: 'kg/(unit)',
    prefix: 'm',
    rules: 'required'
  },
  {
    name: 'carbonStored',
    label: 'Carbon Stored',
    suffix: 'kgCO₂e/M^3',
    prefix: 'm'
  },
  {
    name: 'replacementCycles',
    label: 'Replacement Cycles',
    title: '(x times in 60 years) (1 means no replacement after initial installation)',
    prefix: 'm'
  }
]

export const materialsForm = [
  {
    name: 'name',
    label: 'Name',
    rules: 'required'
  },
  {
    name: 'ccMaterialId',
    label: 'CC Material Id',
    rules: 'required'
  },
  {
    name: 'materialCategory',
    elementType: 'select',
    rules: 'required',
    label: 'Material Category'
  },
  ...materialsFields,
  {
    name: 'notes',
    label: 'Notes'
  },
  {
    name: 'citations',
    label: 'Citations'
  }
]

export const elementTypeHasField = (element) => {
  return element.cpdVersion === 3 && (element.type === 'material' || element.type === 'maintenance')
}
